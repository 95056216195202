<template>
  <div class="px-4 pdf-preview">
    <!-- Header Section -->
    <div class="c-card u-p-small u-mb-small mx-2 pb-2 pt-3 sticky-card">
      <div class="row position-relative px-3 align-items-center">
        <h4 class="mb-1">Image Preview</h4>
        <div class="ml-auto">
          <b-button @click="onPrint" size="md" class="ss-info-bg border-0">
            <i class="fa fa-print u-mr-xsmall"></i>
            Print
          </b-button>
        </div>
        <!-- <p class="mb-1 mr-2">
            <strong>{{ headerSubTitle }}</strong>
          </p> -->
      </div>
    </div>

    <div class="c-card u-p-small u-mb-small mx-2 pt-3 pdf-container h-100vh">
      <LoadingScreen v-if="loading" />
      <div v-if="!loading && url" class="text-center">
        <img :src="url" :alt="documentName" class="preview-image"/>
      </div>
    </div>
  </div>
</template>

<script>
  import LoadingScreen from "@/components/_universal/global/loading-screen.vue";
  import "vue-pdf-app/dist/icons/main.css";
  import print from "print-js";
  import axios from "axios";

  const getSidebar = () => ({
    viewThumbnail: true,
    viewOutline: true,
    viewAttachments: true,
  });
  export default {
    name: "PreviewPDF",
    components: {
      LoadingScreen,
    },
    data() {
      return {
        loading: true,
        url: null,
        documentName: null,
        config: {
          toolbar: false,
          sidebar: getSidebar(),
        },
      };
    },
    created() {
      if (this.$route && this.$route.query && this.$route.query.url) {
        this.url = this.$route.query.url;
        this.documentName = this.$route.query.name;
        this.loading = false;
      }
    },
    methods: {
      openHandler(pdf) {
        let outerContainerHtml = document.getElementById("outerContainer");
        outerContainerHtml.classList.add("sidebarOpen");
        if (pdf && !pdf.pdfSidebar.isOpen) {
          this.config.sidebar = getSidebar();
          pdf.pdfSidebar.isOpen = true;
        }
      },
      onPrint() {
        if (!this.url) return;
        printJS(this.url);
      },
    },
  };
</script>
<style scoped lang="scss">

  .preview-image {
    width: 80vh;
    margin: 0 auto;
  }
  
  .pdf-preview {
    height: 85vh;
    .pdf-container > div {
      height: 100%;
    }
  }
</style>
